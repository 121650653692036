import { useDisplay } from 'vuetify'

export const useMobileDevice = (): ComputedRef<boolean> => {
  const { platform, mobile } = useDisplay()
  const { isMobile } = useDevice()

  return computed(
    () =>
      (platform.value.ssr && isMobile && mobile.value)
      || (!platform.value.ssr && mobile.value),
  )
}
